import React, { useState, useRef, useEffect, useContext } from 'react';
import { FaBars, FaTimes, FaChevronDown } from 'react-icons/fa';
import './Navbar.css';
import { ModalContext } from './ModelContext';
import { Link } from 'react-router-dom';

const Navbar = ({ customClass }) => {
  const { openModal } = useContext(ModalContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const menuRef = useRef(null);
  const dropdownRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setTimeout(() => {
        menuRef.current.classList.add('nav-active');
      }, 10); // Ensure class is added after DOM update
    } else {
      menuRef.current.classList.remove('nav-active');
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current && !menuRef.current.contains(event.target) &&
      dropdownRef.current && !dropdownRef.current.contains(event.target)
    ) {
      setIsOpen(false);
      setIsDropdownOpen(false);
      menuRef.current.classList.remove('nav-active');
    }
  };

  const closeMenu = () => {
    setIsOpen(false);
    setIsDropdownOpen(false);
    menuRef.current.classList.remove('nav-active');
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`navbar ${customClass || ''}`}>
      <div className="navbar-wrapper">
        <div className="navbar-content">
          <div className="logo">
            <a href="/">
              <img src="/rscAssets/HomeAssets/RSCLogo.svg" alt="RavenStone Construction LLC logo" />
            </a>
          </div>
          <div className="quote-container"></div>
          <div className="menu-icon" onClick={toggleMenu}>
            {isOpen ? <FaTimes /> : <FaBars />}
          </div>

          <ul className={`nav-links ${isOpen ? 'nav-active' : ''}`} ref={menuRef}>
            
            <li><Link to="/estimate-form" onClick={closeMenu}>Free Estimates</Link></li>
            <li>
              <a href="#" onClick={(e) => { e.preventDefault(); openModal(); closeMenu(); }}>
                Contact Us
              </a>
            </li>
            <li className="dropdown" ref={dropdownRef}>
              <button
                className="dropdown-menu-arrow"
                onClick={toggleDropdown}
                aria-expanded={isDropdownOpen}
                aria-haspopup="true"
              >
                See Our Work
                <FaChevronDown className={`dropdown-arrow ${isDropdownOpen ? 'rotate' : ''}`} />
              </button>
              <ul className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
                <li><Link to="/services/basements-page" onClick={closeMenu}>Finished Basements</Link></li>
                <li><Link to="/services/exterior-finishing-page" onClick={closeMenu}>Exterior Finishing</Link></li>
                <li><Link to="/services/decks-sheds-page" onClick={closeMenu}>Decks & Sheds</Link></li>
                <li><Link to="/services/floor-tile-page" onClick={closeMenu}>Floor & Tile</Link></li>
                <li><Link to="/services/bathrooms-page" onClick={closeMenu}>Bathrooms</Link></li>
                <li><Link to="/services/kitchens-page" onClick={closeMenu}>Kitchens</Link></li>
                <li><Link to="/services/framing-page" onClick={closeMenu}>Framing</Link></li>
                <li><Link to="/services/extra-services-page" onClick={closeMenu}>Extra Services</Link></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
