import React, { useContext } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './rscComponents/common/Navbar';
import ContactUs from './rscComponents/common/ContactUsBlurb';
import { ModalContext } from './rscComponents/common/ModelContext'; // Import ModalContext
import './App.css';
import ReactGA from 'react-ga4';
import ScrollToTop from './rscComponents/common/ScrollToTop';
import Footer from './rscComponents/common/Footer';

// Lazily load the pages
const HomePage = React.lazy(() => import('./rscPages/HomePage'));
const EstimatePage = React.lazy(() => import('./rscPages/EstimatePage'));
const ExteriorFinishingPage = React.lazy(() => import('./rscPages/rscServicesPages/ExteriorFinishingPage'));
const BasementsPage = React.lazy(() => import('./rscPages/rscServicesPages/BasementsPage'));
const BathroomsPage = React.lazy(() => import('./rscPages/rscServicesPages/BathroomsPage'));
const DeckShedPage = React.lazy(() => import('./rscPages/rscServicesPages/DeckShedPage'));
const FloorTilePage = React.lazy(() => import('./rscPages/rscServicesPages/FloorTilePage'));
const FramingPage = React.lazy(() => import('./rscPages/rscServicesPages/FramingPage'));
const KitchensPage = React.lazy(() => import('./rscPages/rscServicesPages/KitchensPage'));
const MorePage = React.lazy(() => import('./rscPages/rscServicesPages/MorePage'));

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID; // Replace with your actual ID
ReactGA.initialize(TRACKING_ID);

const App = () => {
  const { openModal } = useContext(ModalContext);

  // Track page views on component mount
  React.useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, []);

  return (
    <Router>
      <ScrollToTop /> {/* Add ScrollToTop component */}
      <div className="App">
        {/* Non-lazy-loaded components */}
        <Navbar onContactClick={openModal} />
        
        {/* Lazy-loaded Routes */}
        <React.Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route path="/estimate-form" element={<EstimatePage />} />
            <Route path="/services/exterior-finishing-page" element={<ExteriorFinishingPage />} />
            <Route path="/services/basements-page" element={<BasementsPage />} />
            <Route path="/services/bathrooms-page" element={<BathroomsPage />} />
            <Route path="/services/decks-sheds-page" element={<DeckShedPage />} />
            <Route path="/services/floor-tile-page" element={<FloorTilePage />} />
            <Route path="/services/framing-page" element={<FramingPage />} />
            <Route path="/services/kitchens-page" element={<KitchensPage />} />
            <Route path="/services/extra-services-page" element={<MorePage />} />
          </Routes>
        </React.Suspense>
        
        {/* Non-lazy-loaded components */}
        <ContactUs />
        <Footer />
      </div>
    </Router>
  );
};

export default App;
