// src/components/common/Footer.js
import React, { useContext } from 'react';
import './Footer.css';
import { ModalContext } from './ModelContext'; // Import ModalContext
import { Link } from 'react-router-dom';

const Footer = ({ customFooterClass }) => {
  const { openModal } = useContext(ModalContext); // Use modal context

  return (
    <footer className={`footer ${customFooterClass}`}>
      <div className="footer-content">
        <div className="footer-links">
          <Link to="/estimate-form">Free Estimates</Link>
          <a href="#contact-us" onClick={(e) => { e.preventDefault(); openModal(); }}>Contact Us</a>
        </div>
        <div className="footer-copyright">
          © {new Date().getFullYear()} RavenStone Construction LLC. All rights reserved.
        </div>
        <div className="footer-links">
          <a href="https://ducommuntechstudios.com">Website Developed by DucommunTech Studios LLC</a>
        </div>        
      </div>
    </footer>
  );
};

export default Footer;
