// src/rscComponents/common/ContactUsBlurb.js
import React, { useContext } from 'react';
import Modal from './Modal';
import { ModalContext } from './ModelContext';
import './ContactUsBlurb.css';
import './Modal.css';
import { Link } from 'react-router-dom';

const ContactUs = () => {
  const { isModalOpen, closeModal } = useContext(ModalContext);

  return (
    <Modal show={isModalOpen} onClose={closeModal}>
      <div id="contact-us" className="contact-us-section">
        <div className="contact-container">
          <h2>
            <i className="fas fa-address-book"></i> Contact Us
          </h2>
          <p><i className="fas fa-map-marker-alt"></i> We operate locally in St. Charles County and St. Louis County, MO</p>
          <p><i className="fas fa-envelope"></i> Any questions please submit an estimate form: <Link to="/estimate-form" onClick={closeModal}>Go To Estimate Form</Link></p>
          <p><i className="fas fa-phone"></i> Phone: <a href="tel:314-9527-339">314-952-7339</a></p>
          <p><i className="fas fa-clock"></i> Office Hours: By Appointment Only</p>
        </div>
      </div>
    </Modal>
  );
};

export default ContactUs;
